import { IReader } from "./IReader"

type CookieSetOptions = {
	expires?: number | string | Date
	path?: string
	domain?: string
	sameSite?: string
	httpOnly?: boolean
	secure?: boolean
	other?: string
}

type CookieRemoveOptions = {
	path?: string
	domain?: string
}

function getTimeFromOffset(offsetMs: number) {
	const date = new Date()
	date.setMilliseconds(date.getMilliseconds() + offsetMs)
	return date
}

export class CookieReader implements IReader {
	private _read(value: string): string {
		if (value[0] === '"') {
			value = value.slice(1, -1)
		}
		return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
	}

	private _write(value: string): string {
		return encodeURIComponent(value).replace(
			/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
			decodeURIComponent
		)
	}

	private _get(key: string | null): Record<string, string> {
		const res: Record<string, string> = {}
		if (typeof document === 'undefined' || !key) return res

		const entries = document.cookie.split('; ')
		entries.some((entry) => {
			const parts = entry.split('=')
			const name = decodeURIComponent(parts.shift() ?? '')
			const value = parts.join('=')
			res[name] = this._read(value)
			return name === key
		})
		return res
	}

	get(key: string): string | undefined {
		const cookies = this._get(key)
		return cookies[key]
	}

	set(key: string, value: string, options: CookieSetOptions = {}): void {
		if (typeof document === 'undefined') return

		const name = encodeURIComponent(key)

		const expires = ((exp: unknown): string | undefined => {
			if (exp instanceof Date) return exp.toUTCString()
			switch (typeof exp) {
				case 'number': return getTimeFromOffset(exp).toUTCString()
				default: return exp as string
			}
		})(options.expires)

		document.cookie = [
			`${name}=${this._write(value)}`,
			expires != null ? '; Expires=' + expires : '',
			options.path ? '; Path=' + options.path : '',
			options.domain ? '; Domain=' + options.domain : '',
			options.sameSite ? '; SameSite=' + options.sameSite : '',
			options.httpOnly ? '; HttpOnly' : '',
			options.secure ? '; Secure' : '',
			options.other ? '; ' + options.other : ''
		].join('')
	}

	getAll(): Record<string, string> {
		return this._get(null)
	}

	delete(key: string, options: CookieRemoveOptions = {}): void {
		this.set(key, '', { expires: -1, ...options })
	}
}
