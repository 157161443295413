/**
 * Dispatch route layer
 * Leaflet v1.2.0
 * */

var GEOMETRY_REFRESH_TIMEOUT = 200;
var noop = function() {};


var GeoJSONBuilder = L.Class.extend({

	initialize: function(map) {
		if (map) {
			this.setMap(map);
		}
	},

	setMap: function(map) {
		this._map = map;
	},

	/**
	 * path {
	 * 		id: string,
	 * 		geometry: array
	 * }
	 * */
	fromPath: function(geometry, pathIndex) {
		if (!geometry) return;

		return geometry.reduce(function(res, line, i) {
			res.push({
				type: "Feature",
				properties: { type: "line", routePartNum: i, pathIndex: pathIndex },
				geometry: {
					type: "LineString",
					coordinates: line.map(function(p) { return [p.lon, p.lat] })
				}
			});

			var drawPathPx = { val: 0 };
			var prev = null;
			line.forEach(function(point) {
				if (prev) {
					var arrows = this._constructArrows(prev, point, i, drawPathPx, pathIndex);
					res = res.concat(arrows || []);
				}
				prev = point;
			}, this);

			return res;
		}.bind(this), []);
	},

	_constructArrows: function(fromCoord, toCoord, lineIndex, drawPathPx, pathIndex) {
		var start = this._map.latLngToContainerPoint(fromCoord);
		var end = this._map.latLngToContainerPoint(toCoord);
		var arrowLength = 8;
		var arrowWidth = arrowLength * 3 / 8;
		var maxPixelDistance = 50;

		var dir = end.subtract(start);

		var l = Math.sqrt(dir.x * dir.x + dir.y * dir.y);
		drawPathPx.val += l;

		if (drawPathPx.val < maxPixelDistance) {
			return null;
		}

		drawPathPx.val = 0;

		var dx0 = dir.x / l;
		var dy0 = dir.y / l;
		var dx1 = dy0;
		var dy1 = -dx0;

		var arrowCount = Math.ceil(l / maxPixelDistance);
		var startPos = (l - arrowCount * maxPixelDistance) / 2 + maxPixelDistance / 2;

		var retval = [];

		for(var i = 0; i < arrowCount; ++i) {
			var pl = i * maxPixelDistance + startPos;
			var px = start.x + dir.x * pl / l;
			var py = start.y + dir.y * pl / l;

			retval.push({
				type: "Feature",
				properties: { type: "arrow", routePartNum: lineIndex, pathIndex: pathIndex },
				geometry: {
					type: "Polygon",
					coordinates: [[
						this._screenPointToCoordinates([px, py]),
						this._screenPointToCoordinates([
							px - dx0 * arrowLength + dx1 * arrowWidth,
							py - dy0 * arrowLength + dy1 * arrowWidth
						]),
						this._screenPointToCoordinates([
							px - dx0 * arrowLength - dx1 * arrowWidth,
							py - dy0 * arrowLength - dy1 * arrowWidth
						])
					]]
				}
			});
		}

		return retval;
	},

	_screenPointToCoordinates: function(screenPoint) {
		var mapCoords = this._map.containerPointToLatLng(screenPoint);
		return [mapCoords.lng, mapCoords.lat];
	}

});


/**
 * options {
 * 		editable: boolean,
 * 		activePathId: string,
 * 		lineColor: [string|function],
 * 		inactiveOpacity: number[0-1]
 * }
 * */
L.DispatchRoute = L.FeatureGroup.extend({

	options: {
		editable: false,
		activePathIndex: 0,
		lineColor: 'green',
		inactiveOpacity: 0.5,
		clickable: true,
		style: function(feature, options) {
			var lineColor = options.lineColor;
			var color = lineColor instanceof Function ? lineColor() : lineColor;
			var opacity = feature.properties.pathIndex == options.activePathIndex ? 1 : options.inactiveOpacity;
			return {
					line: {
						color: color,
						weight: 6,
						opacity: opacity
					},
					arrow: {
						color: "white",
						fillColor: "white",
						weight: 0,
						opacity: opacity,
						fillOpacity: opacity
					}
				}[feature.properties.type] || {};
		},
		getPointListsFunction: noop,
		getPointListArrayFunction: noop
	},

	initialize: function(options) {
		L.Util.setOptions(this, options);
		L.FeatureGroup.prototype.initialize.apply(this);

		this._markers = L.featureGroup();
	},

	onAdd: function(map) {
		this._map = map;

		this._geojsonBuilder = new GeoJSONBuilder(map);
		this._markers.addTo(map);

		this._map.on('moveend', this._onMapMoveEnd, this);
	 	this._map.on('zoomend', this._onMapZoomEnd, this);

	 	this.update(); // load data on attach to map
	},

	onRemove: function(map) {

		this._map.off('moveend', this._onMapMoveEnd, this);
		this._map.off('zoomend', this._onMapZoomEnd, this);

		this.clearLayers();
		this._markers.removeFrom(map);

		this._geojsonBuilder.setMap(null);
		this._geojsonBuilder = null;
		this._map = null; // release map
	},

	_onMapMoveEnd: function(e) {
		this.update();
	},

	_onMapZoomEnd: function(e) {
		this.update();
	},

	update: function() {
		if (this._geometryLoadTimeout) {
			clearTimeout(this._geometryLoadTimeout);
		}
		this._geometryLoadTimeout = setTimeout(this._loadGeometry.bind(this), GEOMETRY_REFRESH_TIMEOUT);

		var pointListArray = this.options.getPointListArrayFunction();
		this._markers.clearLayers();

		if (pointListArray && pointListArray.length) {
			pointListArray.forEach(this._makeMarkers, this);

			if (this.options.onEachMarker instanceof Function) {
				this._markers.eachLayer(this.options.onEachMarker);
			}
		}
		this.fire('markers:created');
		this.fire('update');
	},

	redraw: function() {
		var style = this._getStyle();
		this.eachLayer(function(layer) {
			layer.setStyle(style);
		});
		this._markers.eachLayer(this._setMarkerStyle, this);
		this.fire('redraw');
	},

	_setMarkerStyle: function(marker) {
		var pathIndex = marker.options.pathIndex;
		var zIndex = this.options.activePathIndex === pathIndex ? 1 : 0;
		var opacity = this.options.activePathIndex === pathIndex ? 1 : this.options.inactiveOpacity;
		marker.setZIndexOffset(zIndex);
		marker.setOpacity(opacity);
		if (this.options.activePathIndex === pathIndex && this.options.editable && marker.options.draggable) {
			marker.dragging.enable();
		} else {
			marker.dragging.disable();
		}
	},

	_makeMarkers: function(pointList, pathIndex) {
		pointList.forEach(function(point, index) {
			point.pathIndex = pathIndex;
			point.markerIndex = index;
			var marker = L.marker(point.latlng, point);
			this._markers.addLayer(marker);
			this._setMarkerStyle(marker);
		}, this);
	},

	_loadGeometry: function() {
		if (!this._map) return; // layer removed from map

		// NOTE: "loadGeometryFunction" may be sync, wrap it to Promise
		Promise.resolve(this.options.loadGeometryFunction())
			.then(function(geometryList) {

				if (!this._map || !geometryList) return;

				this.clearLayers();
				geometryList = geometryList instanceof Array ? geometryList : [geometryList];

				geometryList.forEach(function(geom, index) {

					var geoJson = this._geojsonBuilder.fromPath(geom, index);

					var layer = L.geoJson(geoJson, {
						pathIndex: index,
						clickable: this.options.clickable,
						onEachFeature: this.options.onEachFeature
					});

					layer.setStyle(this._getStyle());
					this.addLayer(layer);

				}, this);
				this.fire('layers:created');

			}.bind(this));
	},

	_getStyle: function() {
		var options = this.options;
		return function(options, feature) {
			return options.style(feature, options);
		}.bind(this, options);
	},

	setActivePath: function(pathIndex, silent) {
		this.options.activePathIndex = pathIndex | 0;
		if (!silent) {
			this.redraw();
		}
	},

	eachMarker: function(handler, context) {
		this._markers.eachLayer(function(marker) {
			handler.call(context, marker);
		});
	}

});

L.dispatchRoute = function(options) {
	return new L.DispatchRoute(options);
};