/**
 * Modified for Navitel dispatch:
 *
 * Adapted for leaflet v1.2.0
 *
 * Added support:
 * scrollWheelZoom, closeable, draggable, fixedWindow, size, closeButtonPosition
 *
 * Some minor fixes and new methods
 */
L.MagnifyingGlass = L.Layer.extend({

  options: {
    size: null, // Use L.Point if rectagular form is needed
    radius: 100,
    zoomOffset: 3,
    layers: [ ],
    fixedPosition: false,
    latLng: [0, 0],
    fixedZoom: -1,
    scrollWheelZoom: false,
    closeable: false,
    draggable: false,
    fixedWindow: false,
    closeButtonPosition: 'tr'
  },

  initialize: function(options) {
    L.Util.setOptions(this, options);
    this._fixedZoom = (this.options.fixedZoom != -1);
    this._mainMap = null;
    this._glassMap = null;
  },

  getMap: function(){
    return this._glassMap;
  },

  _createMiniMap: function(elt) {
    return new L.Map(elt, {
      layers: this.options.layers,
      zoom: this._getZoom(),
      maxZoom: this._mainMap.getMaxZoom(),
      minZoom: this._mainMap.getMinZoom(),
      crs: this._mainMap.options.crs,
      fadeAnimation: false,
      // disable every controls and interaction means
      attributionControl: false,
      zoomControl: false,
      boxZoom: false,
      touchZoom: false,
      scrollWheelZoom: this.options.scrollWheelZoom,
      doubleClickZoom: false,
      dragging: false,
      keyboard: false
    });
  },

  _getZoom: function() {
    return (this._fixedZoom) ?
      this.options.fixedZoom :
      this._mainMap.getZoom() + this.options.zoomOffset;
  },

  _updateZoom: function() {
    this._glassMap.setZoom(this._getZoom());
  },

  setRadius: function(radius) {
    this.options.radius = radius;
    if(this._wrapperElt) {
      this._wrapperElt.style.width = this.options.radius * 2 + 'px';
      this._wrapperElt.style.height = this.options.radius * 2 + 'px';
    }
  },

  setSize: function(point) {
    this.options.size = point;
    if(this._wrapperElt) {
      this._wrapperElt.style.width = this.options.size.x + 'px';
      this._wrapperElt.style.height = this.options.size.y + 'px';
    }
  },

  setLatLng: function(latLng) {
    this.options.latLng = latLng;
    this._update(latLng);
  },

  _updateFromMouse: function(evt) {
    this._update(evt.latlng, evt.layerPoint);
  },

  _updateFixed: function(evt) {
    this._update(this.options.latLng);
  },

  _updateGlassZoom: function(evt) {
    this.options.fixedZoom = evt.target.getZoom();
    this._update(this.options.latLng);
  },

  _update: function(latLng, layerPoint) {

    if(!this._glassMap) {
      return;
    }

    // update mini map view, forcing no animation
    this._glassMap.setView(latLng, this._getZoom(), {
      pan : { animate: false }
    });

    if(!this._customPosition) {
      // update the layer element position on the main map,
      // using the one provided or reprojecting it
      layerPoint = layerPoint || this._mainMap.latLngToLayerPoint(latLng);
      this.setPosition(layerPoint.x, layerPoint.y);
    }
  },

  setPosition: function(x, y) {

    var offsetX = this.options.radius, offsetY = this.options.radius;

    if(this.options.size) {
      offsetX = this.options.size.x / 2;
      offsetY = this.options.size.y / 2;
    }

    L.DomUtil.setPosition(
      this._wrapperElt,
      L.point(x - offsetX, y - offsetY),
      true
    );
  },

  bringToFront: function() {

    var container = this._mainMap.getContainer();
    var popupPane = this._mainMap.getPanes().popupPane;
    var node = (this.options.fixedWindow && this._customPosition ? container : popupPane);

    if(popupPane.contains(this._wrapperElt)) {
      popupPane.removeChild(this._wrapperElt);
    }

    if(container.contains(this._wrapperElt)) {
      container.removeChild(this._wrapperElt);
    }

    node.appendChild(this._wrapperElt);
  },

  /**
  As defined by ILayer
  */
  onAdd: function(map) {

    var opts = this.options;
    this._mainMap = map;
    // create a wrapper element and a container for the map inside it
    this._wrapperElt = L.DomUtil.create('div', 'leaflet-magnifying-glass');
    var glassMapElt = L.DomUtil.create('div', '', this._wrapperElt);

    if(opts.closeable) {
      var closeNode = L.DomUtil.create('a', opts.closeButtonPosition + '-pos', this._wrapperElt);
      closeNode.href = 'javascript://';
      closeNode.innerHTML = '&#215;';
      L.DomEvent.on(closeNode, 'click', this._onClose, this);
    }

    if(opts.draggable) {
      var dragElt = L.DomUtil.create('p', '', this._wrapperElt);
      this._draggable = new L.Draggable(this._wrapperElt, dragElt);
      this._draggable._updatePosition = function () {
        this.fire('predrag');
        L.DomUtil.setPosition(this._element, this._newPos, true);
        this.fire('drag');
      }.bind(this._draggable);

      this._draggable.on('dragstart', this._onDragStart, this);
      this._draggable.on('dragend', this._onDragEnd, this);
      this._draggable.on('drag', this._onDrag, this);
      this._draggable.enable();
    }

    // forward some DOM events as Leaflet events
    L.DomEvent.addListener(this._wrapperElt, 'click', this._fireClick, this);

    if(opts.size) {
      L.DomUtil.addClass(this._wrapperElt, 'leaflet-magnifying-glass-rect');
      this.setSize(opts.size);
    } else {
      if(L.Browser.webkit) {
        // Webkit border-radius clipping workaround (see CSS)
        L.DomUtil.addClass(glassMapElt, 'leaflet-magnifying-glass-webkit');
      }
      this.setRadius(opts.radius);
    }

    // build the map
    this._glassMap = this._createMiniMap(glassMapElt);
    this.setLatLng(opts.latLng);

    this._glassMap.whenReady(function() {
      if(opts.fixedPosition) {
        this._mainMap.on('zoomend', this._updateFixed, this);
        if(opts.scrollWheelZoom) {
          this._glassMap.on('zoomend', this._updateGlassZoom, this);
        }
        // for now, hide the elements during zoom transitions
        L.DomUtil.addClass(this._wrapperElt, ('leaflet-zoom-hide'));
      } else {
        this._mainMap.on('mousemove', this._updateFromMouse, this);
        if(!this._fixedZoom) {
          this._mainMap.on('zoomend', this._updateZoom, this);
        }
      }
    }, this);

    // add the magnifying glass as a layer to the top-most pane
    this.bringToFront();

    // needed after the element has been added, otherwise tile loading is messy
    this._glassMap.invalidateSize();

    return this;
  },

  _fireClick: function(domMouseEvt) {
    this.fire('click', domMouseEvt);
    L.DomEvent.stopPropagation(domMouseEvt);
  },

  _onClose: function(domMouseEvt) {
    this.fire('close', domMouseEvt);
    L.DomEvent.stopPropagation(domMouseEvt);
  },

  _onDragStart: function(domMouseEvt) {
    if(!this._customPosition) {

      this._customPosition = true;
      this.bringToFront();

      if(this.options.fixedWindow) {
        var layerPoint = this._mainMap.latLngToContainerPoint(this.options.latLng);
        this.setPosition(layerPoint.x, layerPoint.y);
      }
    }
    this.fire('dragstart', domMouseEvt);
    L.DomEvent.stopPropagation(domMouseEvt);
  },

  _onDragEnd: function(domMouseEvt) {
    this.bringToFront();
    this.fire('dragend', domMouseEvt);
    L.DomEvent.stopPropagation(domMouseEvt);
  },

  _onDrag: function(domMouseEvt) {
    this.fire('drag', domMouseEvt);
    L.DomEvent.stopPropagation(domMouseEvt);
  },

  /**
  As defined by ILayer
  */
  onRemove: function(map) {
    map.off('viewreset', this._updateFixed, this);
    map.off('mousemove', this._updateFromMouse, this);
    map.off('zoomend', this._updateZoom, this);
    map.off('zoomend', this._updateFixed, this);

    if(this._draggable) {
      this._draggable.disable();
      delete this._draggable;
    }

    // layers must be explicitely removed before map destruction,
    // otherwise they can't be reused if the mg is re-added
    for(var i=0, l=this.options.layers.length; i<l; i++) {
      this._glassMap.removeLayer(this.options.layers[i]);
    }
    this._glassMap.remove();
    L.DomEvent.removeListener(this._wrapperElt, 'click', this._fireClick);
    if(this.options.fixedWindow && this._customPosition) {
      map.getContainer().removeChild(this._wrapperElt);
    } else {
      map.getPanes().popupPane.removeChild(this._wrapperElt);
    }
    this._mainMap = null;
    this._glassMap = null;

    return this;
  }
});

L.magnifyingGlass = function (options) {
  return new L.MagnifyingGlass(options);
};
