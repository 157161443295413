/* eslint-disable @typescript-eslint/no-explicit-any */
import env from './env'
import { Storage, SessionStorageReader, CookieReader } from './util/storage'
import dojoConfig from 'dojo/_base/config'

const CFG_PREFIX = 'dispatchConfig.'

const sessionStorage = new Storage(new SessionStorageReader())
const cookieReader = new CookieReader()

function detectLocale(): string {
	const locale = cookieReader.get('locale')
		?? navigator.language.split(/[-_]/)[0]
	return locale
}

dojoConfig.locale = detectLocale()

const _config = {
	rpcUrl: 'rpc',
	wsUrl: 'ws',
	iconsUrl: 'icons/',
	uploadUrl: 'upload/',
	uploadLimit: 4096 << 10,
	serverPush: false,
	webSocketReconnectDuration: 10000,
	dispatchHeadPollingInterval: 120000, //NOTE: 120 seconds head polling interval, -1 to disable polling
	dispatchLongRequestDuration: 15000,
	vehicleSelectionLimit: 300,
	portionPollingInterval: 60000, // 1 minute
	versionPollingInterval: 5 * 60 * 1000, // 5 minutes

	get locale() {
		return dojoConfig.locale
	},

	set locale(value: string) {
		dojoConfig.locale = value
		cookieReader.set('locale', value)
	},

	isDebug: dojoConfig.isDebug || env.buildMode === 'development',
}

// public interface
export type DispatchConfig = typeof _config

export const config: DispatchConfig = new Proxy(_config, {
	set: (target: any, key: keyof DispatchConfig, value) => {
		target[key] = value
		sessionStorage.set(`${CFG_PREFIX}${key}`, target[key])
		return true
	},
	get: (target, key: keyof DispatchConfig) => {
		const value = sessionStorage.get(`${CFG_PREFIX}${key}`)
		return value ?? target[key]
	},
})

// don't toch this ;)
;(window as any).dispatchConfig = config

export default config
