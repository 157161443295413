import { IReader } from "./IReader"

export class SessionStorageReader implements IReader {
	get(key: string): string | undefined {
		return globalThis.sessionStorage.getItem(key) ?? undefined
	}

	set(key: string, value: string): void {
		globalThis.sessionStorage.setItem(key, value)
	}

	delete(key: string): void {
		globalThis.sessionStorage.removeItem(key)
	}

	getAll(): Record<string, string> {
		const res: Record<string, string> = {}
		for (let i = 0; sessionStorage.length; i++) {
			const key = sessionStorage.key(i)
			if (!key) continue
			res[key] = sessionStorage.getItem(key) ?? ''
		}
		return res
	}
}
