/**
 * !WARNING: should be compatible with CommonJS modules to use with node and web
 */
const versionConsumer = process.env.VERSION_CONSUMER || ''
const buildMode = process.env.NODE_ENV || 'production'

/**
 * Pattern:
 *     major.minor.revision.patch
 *     major.minor.revision.build
 *
 * Ex: 2.2.5.1648
 *
 * @typedef {typeof buildVersion} BuildVersion
 */
const buildVersion = {
	major: process.env.VERSION_MAJOR | 0,
	minor: process.env.VERSION_MINOR | 0,
	revision: process.env.VERSION_REVISION | 0,
	build: process.env.VERSION_PATCH | 0,
	toJSON() {
		return versionToString(this)
	}
}

function versionToString({ major, minor, revision, build }) {
	return `${major}.${minor}.${revision}.${build}`
}

function getVersionFileName(version) {
	return `app_version_${versionToString(version)}.json`
}

module.exports = {
	buildMode,
	versionConsumer,
	buildVersion,
	versionToString,
	getVersionFileName,
}

// TS typings

