import { IReader } from "./reader/IReader"

export class Storage<T extends Record<string, unknown>> {
	constructor(
		private readonly reader: IReader,
	) {}

	get<K extends keyof T>(key: K): T[K] | undefined {
		const value = this.reader.get(key as string)
		return value != null ? JSON.parse(value) : undefined
	}

	set<K extends keyof T>(key: K, value: T[K]): void {
		this.reader.set(key as string, JSON.stringify(value))
	}
}
